<template>
    <div class="q-pa-md top-level row q-gutter-md justify-evenly items-stretch">

        <q-card class="my-card" v-for="(doc,idx) in docs" :key="'doc_'+idx">
            <q-card-section class="card-section-picture vertical-middle q-card__section q-card__section--vert fit row wrap justify-center items-center content-center" @click="openPopup(idx)">
                <img v-if="doc.picture" :src="doc.picture" style="width:100%; height:auto; padding:.5em;"/>
                <div v-else style="font-weight:bold; font-size:2em; font-family:sans-serif;" class="vertical-middle">{{doc.title}}</div>
            </q-card-section>
        </q-card>

        <q-dialog v-model="showdialog">
            <q-card class="my-card2">
                <q-img src="docs[current].picture" />

                <q-card-section>
                    <div class="row no-wrap items-center">
                        <div class="col text-h6 ellipsis" v-html="docs[current].title" />
                    </div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <div class="text-subtitle1" v-html="docs[current].short" />
                    <div class="text-caption text-grey" v-html="docs[current].description" />
                </q-card-section>

                <q-separator />

                <q-card-actions align="right">
                    <q-btn v-if="docs[current].url" flat color="primary" label="OPEN" />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>

<script>
export default ({
    name: 'Projects',
    data() {
        return {
            showdialog: false,
            current: 0,
            docs: [
                {
                    picture: '/static/projects/cass_logo_black_nobackgr.png',
                    title: 'CREATIVE Associates',
                    short: '',
                    description: '',
                    year: '',
                    url: '',
                },
                {
                    picture: '/static/projects/european_parliament.png',
                    title: 'European Parliament',
                    short: '',
                    description: '',
                    year: '.',
                    url: '',
                },
                {
                    picture: '/static/projects/european_commission.png',
                    title: 'European Commission',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/base.png',
                    title: 'BASE',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/vreg.png',
                    title: 'Vlaams regulator van de electriciteits-en gasmarkt (VREG)',
                    short: 'Clearing system',
                    description: 'Architecture, design, API design, implementation and management of a clearing system for the VREG (Vlaams regulator van de electriciteits-en gasmarkt), including data interchange design between electricity or gas transporters, providers and producers.',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/mastercard.png',
                    title: 'Mastercard International',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/kblux.jpeg',
                    title: 'KB-Lux',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/kbsuisse.png',
                    title: 'KB-Suisse',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/policebxl.jpeg',
                    title: 'Police de Bruxelles',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '',
                    title: 'Créa-Vert',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/zikzak.png',
                    title: 'Zik-Zak',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/espace165.png',
                    title: 'Espace 165',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '',
                    title: 'Sysmito',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/musique.jpeg',
                    title: 'Musical projects',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/sncb.png',
                    title: 'SNCB',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/tessenderlo.png',
                    title: 'Tessenderlo Group',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/teacher.png',
                    title: 'Benevolent, coaching',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/astrid.png',
                    title: 'ASTRID',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/hp.png',
                    title: 'HP Belgium',
                    short: 'Perl class teacher',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/astra.png',
                    title: '',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/fortis.jpeg',
                    title: '',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '/static/projects/dhl.png',
                    title: '',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
                {
                    picture: '',
                    title: 'filler',
                    short: '',
                    description: '...',
                    year: '...',
                    url: '',
                },
            ],
        };
    },
    methods: {
        openPopup(idx){
            this.current = idx;
            this.showdialog = true;
        },
    },
})
</script>

<style scoped lang="less">
.my-card {
    max-width: 200px;
    min-width: 200px;
    height: 150px;
    color: #444;
    overflow: hidden;
    padding: 0;
    // display: flex;
    // flex-direction: column;
    .card-section-picture {
        // flex:0 0 200px;
        text-align: center;
        overflow:hidden;
        padding: 0;
    }
    .card-section-description {
        // flex: 1 1;
        background-color:#f0f0f0;
        border-top: 1px solid DarkGray;
        border-bottom: 1px solid DarkGray;
        .text-h6 {
            font-weight: bold;
        }
    }
    .q-card__actions {
        flex:0 0;
        .openUrl {
            text-decoration: none;
            color: #555;
            font-weight: bolder;
        }
    }
}
</style>
